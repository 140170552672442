import { get, has, isArray, isEmpty } from 'lodash';
import history from '../../../../../../../Services/history';
import { FormQuestion, FormQuestionOption } from '../../../../../../../Utils/Types/FormBuilder';
import { Locales } from '../../../../../../../Utils/Types';

export const convertQuestionOptionIdsToArray = (answer: any) => {
  if (has(answer, 'question_option_ids') && !isArray(answer?.question_option_ids)) {
    return { ...answer, question_option_ids: [answer.question_option_ids] };
  }

  return answer;
};

export const convertAttachmentsObjectsToArray = (answer: any) => {
  if (has(answer, 'attachments') && isArray(answer?.attachments)) {
    return {
      ...answer,
      attachments_attachments_attributes: answer.attachments.map((attachment: any) => ({
        id: attachment.id,
        blob_id: attachment.blob_id,

        _destroy: attachment._destroy,
      })),
    };
  }

  return answer;
};

export const filterQuestionOptionIds = (answer: any, questions: any) => {
  const emptyContentI18n = Locales.every((locale) =>
    isEmpty(get(answer, `content_i18n_${locale.value}`)),
  );

  if (!isEmpty(get(answer, 'question_option_ids')) && emptyContentI18n) {
    // remove question_option_ids that belongs to a question_option «other» if answer content is null
    const filteredIds = answer.question_option_ids.filter((questionOptionId: any) => {
      const question = questions.find((q: any) =>
        q.question_options?.some((option: any) => option.id === questionOptionId),
      );
      const questionOption = question?.question_options?.find(
        (option: any) => option.id === questionOptionId,
      );
      return !questionOption?.other;
    });

    return { ...answer, question_option_ids: filteredIds };
  }

  return answer;
};

export const convertMomentDateToString = (answer: any) => {
  if (has(answer, 'date') && answer.date) {
    return { ...answer, date: answer?.date?.format('YYYY-MM-DD') };
  }

  return answer;
};

export const submitForm = ({ data, setLoading, submitFormResponse, questions }: any) => {
  setLoading(true);

  const transformedData = {
    ...data,

    answers_attributes: data.answers_attributes
      .filter((answer: any) => !answer.hidden) // we don't send hidden fields
      .map(convertQuestionOptionIdsToArray)
      .map(convertAttachmentsObjectsToArray)
      .map((answer: any) => filterQuestionOptionIds(answer, questions))
      .map(convertMomentDateToString),
  };

  submitFormResponse(transformedData);
};

export function transformKeys(obj: any) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    // Replace and reformat the key for answers_attributes
    const newKey = key.replace(/answers\[(\d+)\]/, 'answers_attributes.$1');

    newObj[newKey] = obj[key];
  });
  return newObj;
}

export function remapCheckBoxesValues(
  value: any,
  isChecked: any,
  questionOption: any,
  onChange: (...event: any[]) => void,
) {
  const valueFallback = value || [];
  const newValues = isChecked
    ? valueFallback.filter((id: string) => id !== questionOption.id)
    : [...valueFallback, questionOption.id];
  onChange(newValues);
}

export function checkOtherCheckbox(
  question: FormQuestion,
  questionOptionsIdsController: any,
  answerAttributes: any,
) {
  const questionOptionsIds = question.question_options
    ?.filter((questionOption: FormQuestionOption) => questionOption.other)
    .map((questionOption: FormQuestionOption) => questionOption.id);

  const uniqueIds = [...(answerAttributes.question_option_ids || []), ...questionOptionsIds].filter(
    (value: any, index: any, self: any) => self.indexOf(value) === index,
  );

  questionOptionsIdsController.field.onChange(uniqueIds);
}

export const triggerSuccessModal = () => {
  // Public submission just get the empty state
  history.push({
    pathname: window.location.pathname,
    search: `?public_sucess=true`,
  });
};
