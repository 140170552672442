import { isArray } from 'lodash';

// Adds formatting for react-hook-form standart errors format when used with setErrors
// when use with redux it’s not required but used for the sake of consistency

export type ApiErrorType = {
  type: string;
  message: string;
};

export type ApiErrorsType = Record<string, ApiErrorType>;

export default (messages: any) => {
  const errors = messages[0];

  // if errors is an object loop otherwise return the formatted error
  if (typeof errors === 'object') {
    const fields = Object.keys(errors);
    return fields.reduce((obj: ApiErrorsType, field) => {
      obj[field] = isArray(errors[field])
        ? { type: 'manual', message: errors[field].join(', ') }
        : { type: 'manual', message: errors[field] };
      return obj;
    }, {});
  }

  return { type: 'manual', message: errors };
};
